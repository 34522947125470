import { graphql } from 'gatsby'
import Loadable from "@loadable/component"

const LoadablePrivacy = Loadable(() => import("../components/Privacy"))
export default LoadablePrivacy

export const query = graphql`
  query{
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "privacy"}}}) {
      edges {
        node {
          frontmatter {
            fileName
            channel
            lang
            type
          }
          html
        }
      }
    }
  }
`
